<template>
	<div class="page">
		<div class="page-item">
			<div class="page-item-left">
				<div class="page-item-left-head">
					<img src="@/assets/arrowright.png" />
					<span>经营数据</span>
				</div>
				<div class="page-item-left-list">
					<img src="@/assets/xxpx2.png" />
				</div>
			</div>
			<div class="page-item-right">
				<div class="page-item-right-yjhjgj">
					<!-- <div class="page-item-right-yjhjgj-title">
						后端数据匹配，一键直达管家
					</div> -->
					<div class="page-item-right-yjhjgj-content">
						商铺APP的经营数据功能旨在为商场马尼拉提供实时、精彩的业务数据和分析，以便他们能够深入了解商场绩效、趋势和客户行为，从而做出更明智的决策。
					</div>
				</div>
			</div>
		</div>
		<div class="page-item">
			<div class="page-item-left">
				<div class="page-item-left-head">
					<img src="@/assets/arrowright.png" />
					<span>报事报修</span>
				</div>
				<div class="page-item-left-bmfw">
					<!-- <div class="page-item-left-bmfw-title">
						周边信息全覆盖，移动黄页
					</div> -->
					<div class="page-item-left-bmfw-content">
						商铺APP的报事报修功能旨在为用户提供便捷的渠道，让他们能够快速提交报修请求或报告问题。用户可以通过APP选择问题类型、上传照片并提供详细描述，从而帮助商场管理员和维修人员更好地理解问题。
					</div>
				</div>
			</div>
			<div class="page-item-right">
				<div class="page-item-right-list">
					<img src="@/assets/xxpx9.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ExploreU'
	}
</script>

<style scoped lang="scss">
	.page {
		min-width: 1200px;

		.page-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1200px;
			height: 80vh;
			min-height: 900px;
			margin: 0 auto;

			.page-item-left {
				flex: 1;

				.page-item-left-head {
					display: flex;
					flex-direction: column;

					img {
						width: 40px;
					}

					span {
						margin-top: 10px;
						font-size: 30px;
					}
				}

				.page-item-left-content {
					margin-top: 10px;
					font-size: 20px;
				}

				>img {
					width: 100%;
					margin-top: 20px;
				}

				.page-item-left-list {
					margin-top: 20px;

					img {
						width: 45%;
					}

					img:last-child {
						margin-left: 20px;
					}
				}

				.page-item-left-bmfw {
					display: flex;
					flex-direction: column;
					width: 55%;

					.page-item-left-bmfw-title {
						margin-top: 40px;
						font-size: 25px;
						color: rgba(62, 106, 175, 1);
					}

					.page-item-left-bmfw-content {
						margin-top: 30px;
						font-size: 20px;
					}
				}
			}

			.page-item-right {
				display: flex;
				justify-content: flex-end;
				flex: 1;

				>img {
					width: 45%;
				}

				.page-item-right-yjhjgj {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 55%;
					margin-top: 50px;

					>img {
						width: 80%;
					}

					.page-item-right-yjhjgj-title {
						margin-top: 40px;
						font-size: 25px;
						color: rgba(62, 106, 175, 1);
					}

					.page-item-right-yjhjgj-content {
						margin-top: 30px;
						font-size: 20px;
					}
				}

				.page-item-right-list {

					img {
						width: 45%;
					}

					img:last-child {
						margin-left: 20px;
					}
				}
			}
		}

		.page-item:first-child {
			margin-top: 0;
		}
	}
</style>